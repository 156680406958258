export default [
  {
    title: 'Komtim',
    customicon: `<svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.8447 7.53294C19.8987 6.47394 20.8031 5.65881 21.8651 5.71374C22.9271 5.76757 23.7445 6.66948 23.6894 7.72848C23.5363 10.6715 22.2958 13.4355 20.3713 15.4425C18.4611 17.4342 15.8767 18.6997 12.9871 18.703L1.92234 18.7184C0.860371 18.7184 0 17.8604 0 16.8014C0 15.7424 0.860371 14.8844 1.92234 14.8844L12.9871 14.8691C14.7563 14.8669 16.3669 14.0616 17.5809 12.7961C18.8929 11.4273 19.739 9.54219 19.8436 7.53294H19.8447Z" fill="#34A853"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.0004 0.281586C23.1043 0.281586 23.9999 1.17471 23.9999 2.27545C23.9999 3.3762 23.1043 4.26932 22.0004 4.26932C20.8966 4.26932 20.001 3.3762 20.001 2.27545C20.001 1.17471 20.8966 0.281586 22.0004 0.281586Z" fill="#34A853"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.69611 0.281586C9.80104 0.281586 10.6967 1.17471 10.6967 2.27545C10.6967 3.3762 9.80104 4.26932 8.69721 4.26932C7.59338 4.26932 6.69775 3.3762 6.69775 2.27545C6.69775 1.17471 7.59228 0.281586 8.69611 0.281586Z" fill="#00832D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.26208 9.55556C7.19571 9.55556 6.33203 8.6932 6.33203 7.6309C6.33203 6.5686 7.19681 5.70624 8.26208 5.70624H21.7669C22.8333 5.70624 23.697 6.5686 23.697 7.6309C23.697 8.6932 22.8322 9.55556 21.7669 9.55556H8.26208Z" fill="#34A853"/>
        <path d="M16.1137 5.73282H8.22609C7.18084 5.73282 6.3335 6.5778 6.3335 7.62013V7.62123C6.3335 8.66356 7.18084 9.50854 8.22609 9.50854H16.1137C17.159 9.50854 18.0063 8.66356 18.0063 7.62123V7.62013C18.0063 6.5778 17.159 5.73282 16.1137 5.73282Z" fill="#00832D"/>
        </svg>`,
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Dashboard',
        komicon: 'k-home-hashtag',
        tagVariant: 'light-warning',
        resource: 'Komtim - Dashboard',
        route: 'admin-dashboard-komtim',
        action: 'manage',
      },
      {
        title: 'Talent',
        komicon: 'k-user-add',
        tagVariant: 'light-warning',
        resource: 'Komtim - Talent',
        action: 'manage',
        children: [
          {
            title: 'Kuisioner',
            tagVariant: 'light-warning',
            route: 'kuisioner',
            resource: 'Komtim - Talent',
            action: 'manage',
          },
          {
            title: 'Daftar Talent',
            route: 'talent',
            resource: 'Komtim - Talent',
            action: 'manage',
          },
          {
            title: 'Request Edit',
            route: 'request-edit',
            resource: 'Komtim - Talent',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Training',
        customicon: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 22.58C11.7 22.58 11.4 22.51 11.15 22.37C9.28 21.35 5.99 20.27 3.93 20L3.64 19.96C2.33 19.8 1.25 18.57 1.25 17.24V5.15999C1.25 4.36999 1.56 3.64999 2.13 3.12999C2.7 2.60999 3.44 2.35999 4.22 2.42999C6.42 2.59999 9.74 3.69999 11.62 4.87999L11.86 5.01999C11.93 5.05999 12.08 5.05999 12.14 5.02999L12.3 4.92999C14.18 3.74999 17.5 2.62999 19.71 2.43999C19.73 2.43999 19.81 2.43999 19.83 2.43999C20.56 2.36999 21.31 2.62999 21.87 3.14999C22.44 3.66999 22.75 4.38999 22.75 5.17999V17.25C22.75 18.59 21.67 19.81 20.35 19.97L20.02 20.01C17.96 20.28 14.66 21.37 12.83 22.38C12.59 22.52 12.3 22.58 12 22.58ZM3.98 3.91999C3.66 3.91999 3.37 4.02999 3.14 4.23999C2.89 4.46999 2.75 4.79999 2.75 5.15999V17.24C2.75 17.83 3.26 18.4 3.83 18.48L4.13 18.52C6.38 18.82 9.83 19.95 11.83 21.04C11.92 21.08 12.05 21.09 12.1 21.07C14.1 19.96 17.57 18.82 19.83 18.52L20.17 18.48C20.74 18.41 21.25 17.83 21.25 17.24V5.16999C21.25 4.79999 21.11 4.47999 20.86 4.23999C20.6 4.00999 20.27 3.89999 19.9 3.91999C19.88 3.91999 19.8 3.91999 19.78 3.91999C17.87 4.08999 14.79 5.11999 13.11 6.16999L12.95 6.27999C12.4 6.61999 11.62 6.61999 11.09 6.28999L10.85 6.14999C9.14 5.09999 6.06 4.07999 4.1 3.91999C4.06 3.91999 4.02 3.91999 3.98 3.91999Z" fill="#292D32"/>
          <path d="M12 21.74C11.59 21.74 11.25 21.4 11.25 20.99V5.99001C11.25 5.58001 11.59 5.24001 12 5.24001C12.41 5.24001 12.75 5.58001 12.75 5.99001V20.99C12.75 21.41 12.41 21.74 12 21.74Z" fill="#292D32"/>
          <path d="M7.75 9.74001H5.5C5.09 9.74001 4.75 9.40001 4.75 8.99001C4.75 8.58001 5.09 8.24001 5.5 8.24001H7.75C8.16 8.24001 8.5 8.58001 8.5 8.99001C8.5 9.40001 8.16 9.74001 7.75 9.74001Z" fill="#292D32"/>
          <path d="M8.5 12.74H5.5C5.09 12.74 4.75 12.4 4.75 11.99C4.75 11.58 5.09 11.24 5.5 11.24H8.5C8.91 11.24 9.25 11.58 9.25 11.99C9.25 12.4 8.91 12.74 8.5 12.74Z" fill="#292D32"/>
          </svg>`,
        tagVariant: 'light-warning',
        resource: 'Komtim - Training',
        action: 'manage',
        children: [
          {
            title: 'Trainer',
            tagVariant: 'light-warning',
            route: 'instructor',
            resource: 'Komtim - Training',
            action: 'manage',
          },
          {
            title: 'Skill',
            tagVariant: 'light-warning',
            route: 'skill',
            resource: 'Komtim - Training',
            action: 'manage',
          },
          {
            title: 'Kurikulum',
            tagVariant: 'light-warning',
            route: 'curriculum',
            resource: 'Komtim - Training',
            action: 'manage',
          },
          {
            title: 'Training Center',
            tagVariant: 'light-warning',
            route: 'training-center',
            resource: 'Komtim - Training',
            action: 'manage',
          },
          {
            title: 'Training Program',
            tagVariant: 'light-warning',
            route: 'training-program',
            resource: 'Komtim - Training',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Partner',
        komicon: 'k-user-tick',
        route: 'partner',
        resource: 'Komtim - Partner',
        action: 'manage',
      },
      {
        title: 'Device',
        komicon: 'k-mobile',
        route: 'device',
        resource: 'Komtim - Device',
        action: 'manage',
      },
      {
        title: 'Assignment',
        komicon: 'k-card-tick',
        tagVariant: 'light-warning',
        resource: 'Komtim - Assignment',
        action: 'manage',
        children: [
          {
            title: 'Assign Partner',
            route: 'assignment-partners',
            resource: 'Komtim - Assignment',
            action: 'manage',
          },
        ],
      },
      {
        title: 'Evaluasi Partner',
        komicon: 'k-people',
        route: 'evaluation',
        resource: 'Komtim - Evaluasi Partner',
        action: 'manage',
      },
      {
        title: 'Churn Partner',
        komicon: 'k-user-minus',
        route: 'churn-partner',
        resource: 'Komtim - Churn Partner',
        action: 'manage',
      },
      {
        title: 'Keuangan',
        komicon: 'k-moneys',
        tagVariant: 'light-warning',
        resource: 'Komtim - Keuangan',
        action: 'manage',
        children: [
          {
            title: 'Biaya Admin',
            route: 'admin-fees',
            resource: 'Komtim - Keuangan',
            action: 'manage',
          },
          {
            title: 'Sharing Fee',
            route: 'sharing-fees',
            resource: 'Komtim - Keuangan',
            action: 'manage',
          },
          {
            title: 'Invoice',
            route: 'invoices-admin',
            resource: 'Komtim - Keuangan',
            action: 'manage',
          },
        ],
      },
      // {
      //   title: 'Komtim (Old)',
      //   komicon: 'k-clock-1',
      //   tagVariant: 'light-warning',
      //   resource: 'Komtim - Komtim (Old)',
      //   action: 'manage',
      //   children: [
      //     {
      //       title: 'Daftar Partner',
      //       route: 'partners',
      //       resource: 'Komtim - Komtim (Old)',
      //       action: 'manage',
      //     },
      //     {
      //       title: 'Daftar Talent',
      //       route: 'talents',
      //       resource: 'Komtim - Komtim (Old)',
      //       action: 'manage',
      //     },
      //   ],
      // },
    ],
  },
]
